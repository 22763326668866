const oktaSignInConfig = {
    // Note: If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    // TODO: pull out client id and issuer to put in heroku env
    issuer: 'https://dev-9116264.okta.com/oauth2/default',
    clientId: '0oabnh8coinSnRinU5d6',
    redirectUri: window.location.origin + '/login/callback',
    pkce: true
};

// const oktaSignInConfig = {
//     baseUrl: 'https://dev-9116264.okta.com',
//     clientId: '0oa4qho6huJrkw6Xt5d6',
//     redirectUri: window.location.origin + '/login/callback',
//     logo: 'public/images/ts-logo.png',
//     authParams: {
//         // If your app is configured to use the Implicit Flow
//         // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//         // you will need to uncomment the below line
//         // pkce: false
//     }
// };

const config = {
    version: '0.9.2',
    oktaPasswordRecoveryURL: 'https://dev-9116264.okta.com/api/v1/authn/recovery/password',
    oktaUserCookieName: "ts-dash-user",
    rememberUserLoginCookieName: "ts-dash-login",
    loggedInHomeEndpoint: "/",
};

export {
    oktaSignInConfig,
    config
};