import React, {
	useState,
	useEffect
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
// import { Link, useHistory } from 'react-router-dom';
// import { useCookies } from 'react-cookie';

import { config } from '../../config.js';

export default function LoginForm({ lang }) {

	const [username, setUsername] = useState('');

	const [status, setStatus] = useState(null);

	const handleUsernameInput = (e) => {
		setUsername(e.target.value);
		setStatus(null);
	}

	const submitLogin = (e) => {

		e.preventDefault();

		fetch(config.oktaPasswordRecoveryURL, {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					username,
					factorType: "EMAIL",
				})
			})
			.then(response => response.json())
			.then(data => {
				// console.log('Success:', data);
				if (data && data.factorResult === "WAITING") {
					setStatus('waiting');
				} else {
					setStatus('error');
				}
			})
			.catch((error) => {
				// console.error('Error:', error);
				// This rarely happens, means bad entry
				setStatus('error');
			});
	}


	return (
		<div className="LoginForm">

			<form>
				<div className="field">
			    	<label>{lang['login4']}</label>
			    	<input id="emailAddress" type="email" value={username} onChange={handleUsernameInput} autoComplete="username" />
		    	</div>

		    	<input onClick={submitLogin} type="submit" className="button" type="submit" value={lang['login2']} />

		    	{ status === 'waiting' && lang['login7'] }
		    	{ status === 'error' && lang['login8'] }

			</form>

		</div>
	)
}