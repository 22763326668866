import React, { useState, useEffect, useRef } from 'react';
import Switch from "react-switch";

import logo from '../../images/logos/af-logo.svg';
import plusIcon from '../../images/icons/screens/white-plus.svg';
import openReport from '../../images/icons/analytics/open-report.svg';
// import gearIcon from '../../images/icons/menu/gear.svg';
import adminIcon from '../../images/icons/admin.svg';
import whiteGearIcon from '../../images/icons/screens/white-gear.svg';
import returnIcon from '../../images/icons/screens/return.svg';
import saveIcon from '../../images/icons/screens/save.svg';
import keyIcon from '../../images/icons/key.svg';
import addImage from '../../images/icons/add-image.svg';
import deleteIcon from '../../images/icons/analytics/trash.svg';
import uploadIcon from '../../images/icons/upload.svg';

import { saveCustomer } from './utils';
import { getCustomers } from '../../utils'
import AddressField from './AddressField';

const CustomersPage = ({
    userData,
    lang
}) => {

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [savedCustomer, setSavedCustomer] = useState({});
    const [showCustomersModal, setShowCustomersModal] = useState(false);
    const [showManageCustomerModal, setShowManageCustomerModal] = useState(false);
    // const [selectedTab, setSelectedTab] = useState('screen-config');
    const [uploadedImage, setUploadedImage] = useState({
		file: null,
		name: null,
		type: null,
		url: null
	});
    const dropFile = useRef(null);
    const fileInput = useRef(null);

    const onUpload = async (upload) => {
		const file = upload[0];
		//Only accept image types jpeg/jpg and gif
		// if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/gif') {
        if (file.type !== 'image/svg+xml') {
			// setTypeError(true);
			return;
		}
		const objectUrl = URL.createObjectURL(file);
    	setUploadedImage({
			file: file,
			name: file.name,
			type: file.type.split('/')[1],
			url: objectUrl
		});
		// setTypeError(false);
	};

    const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	  
		const {files} = e.dataTransfer;
	  
		if (files && files.length) {
		  onUpload(files);
		}
	};

    const handleClick = () => {
        fileInput.current.click();
    };

    const customerFetch = async () => {
        await getCustomers()
        .then(data => {
            setCustomers(data);
        })
    }

    useEffect(() => {
        customerFetch();

		//Add event lister for file upload
		dropFile.current.addEventListener('dragover', handleDragOver);
		dropFile.current.addEventListener('drop', handleDrop);
	  
		return () => {
			dropFile.current.removeEventListener('dragover', handleDragOver);
			dropFile.current.removeEventListener('drop', handleDrop);
		};
	}, []);

    const handleInputChange = (property, value) => {
		const tempCustomer = {...selectedCustomer};
        tempCustomer[property] = value;
        setSelectedCustomer(tempCustomer);
    }

    const handleColorChange = (property, value) => {
		const tempCustomer = {...selectedCustomer};	
		if (value !== '') {
			tempCustomer[property] = value.includes('#') ? value : `#${value}`;
		}
		else {
			tempCustomer[property] = '';
		}

		setSelectedCustomer(tempCustomer);
    }

    const handleCheckboxChange = (nav, value) => {

        if (!selectedCustomer?.id) return;

        let tempNavs = [...selectedCustomer?.enabledDash2Navs];
        if (value) {
            tempNavs.push(nav);
        }
        else {
            tempNavs = tempNavs.filter(item => item !== nav);
        }

        setSelectedCustomer({
            ...selectedCustomer,
            enabledDash2Navs: tempNavs
        });
    }

    const handleCustomerSelection = (customer) => {
        setSelectedCustomer(customer);
        setSavedCustomer(customer);
        setShowCustomersModal(false);
    }

    const handleSave = async() => {
        await saveCustomer(selectedCustomer)
        .then(async data => {
            if (data.status === 200) {
                //Success so update customers
                await customerFetch();
                setSavedCustomer(selectedCustomer);
            }
        })
    }

    const selectAddress = (address) => {
        const tempCustomer = {...selectedCustomer};	
        tempCustomer.city = address.city;
        tempCustomer.zip = address.zip;
        tempCustomer.state = address.county;
        tempCustomer.address1 = address.address;

        setSelectedCustomer(tempCustomer);
    }

    return (
        <div className="CustomersPage">
            <div className="page-name pure-u-12-24 align-right">
				<img id="action-figure-logo" src={logo} alt="action figure logo"/>
			</div>
            {
                showCustomersModal
                ? <OpenCustomersModal 
                    customers={customers} 
                    selectedCustomer={selectedCustomer}
                    handleCustomerSelection={handleCustomerSelection}
                    setShowCustomersModal={setShowCustomersModal} />
                : <></>
            }
            {
                showManageCustomerModal
                ? <ManageCustomerModal
                    selectedCustomer={selectedCustomer}
                    setShowManageCustomerModal={setShowManageCustomerModal}
                    handleInputChange={handleInputChange}
                />
                : <></>
            }
            <div className="screen-actions">
                <div className="buttons-container pure-u-1-9">
                    <button className="open-screen">
                        <img className="open-icon" src={plusIcon}/>
                    </button>
                    <button className="open-screen" onClick={() => setShowCustomersModal(true)}>
                        <img className="open-icon" src={openReport}/>
                    </button>
                </div>                
				<div className="screen-name pure-u-1-7">
					{
						selectedCustomer?.name
						? <div className="name-container">
							<p>{selectedCustomer?.name}</p>
							<p>{selectedCustomer?.address1}</p>
						</div>
						: <div className="name-container">
							<p>No customer selected.</p>
							<p>Select a customer to view configurations</p>
						</div>
					}
				</div>
				<div className="customer-tabs pure-u-1-2">
					<div className={`icon-container selected-tab`} title="Screen configuration">
						<img className="icon" src={whiteGearIcon} />
					</div>
                    <div className={`icon-container`} title="Open in Admin v1" onClick={selectedCustomer?.id ? ()=> window.open(`https://transitscreen.io/admin/customers/edit/${selectedCustomer.transitScreenId}`, "_blank") : null}>
						<img className="icon" src={adminIcon} />
					</div>
					<div className="vertical-line"></div>
                    <div className={`icon-container`} title="Undo all changes" onClick={() => setSelectedCustomer(savedCustomer)}>
						<img className="icon" src={returnIcon} />
					</div>
                    <div className={`icon-container`} title="Save changes" onClick={selectedCustomer?.id ? () => handleSave(selectedCustomer) : null}>
						<img className="icon" src={saveIcon} />
					</div>
                    <div className="vertical-line"></div>
                    <div className={`icon-container`} title="Manage license" onClick={selectedCustomer?.id ? () => setShowManageCustomerModal(true) : null}>
						<img className="icon" src={keyIcon} />
					</div>
				</div>                
			</div>
            <div className="customers-box">
                <div className="left-col pure-u-1-3">
                    <h3>Customers</h3>
                    <h4>Company information</h4>
                    <p>This will be the legal name, address, and phone number for your primary office location or corporate registration.</p>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Company name:</p>
                        <input className="input" value={selectedCustomer?.name || ''} onChange={(e) => handleInputChange("name", e.target.value)}></input>
                        <div className="color-preview" style={{visibility: 'hidden'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Address:</p>
                        <AddressField 
                            handleAddressChanges={handleInputChange}
                            selectAddress={selectAddress}
                            value={ selectedCustomer.address1 || '' }
                            propName='address1'
                        />
                        <div className="color-preview" style={{visibility: 'hidden'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Phone:</p>
                        <input className="input" value={selectedCustomer?.phone || ''} onChange={(e) => handleInputChange("phone", e.target.value)}></input>
                        <div className="color-preview" style={{visibility: 'hidden'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Website:</p>
                        <input className="input" value={selectedCustomer?.website || ''} onChange={(e) => handleInputChange("website", e.target.value)}></input>
                        <div className="color-preview" style={{visibility: 'hidden'}}></div>
                    </div>
                    <h4>Branding</h4>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Primary color:</p>
                        <input className="input" value={selectedCustomer?.primaryColor || ''} onChange={(e) => handleColorChange("primaryColor", e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: selectedCustomer?.primaryColor || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Secondary color:</p>
                        <input className="input" value={selectedCustomer?.secondaryColor || ''} onChange={(e) => handleColorChange("secondaryColor", e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: selectedCustomer?.secondaryColor || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Background:</p>
                        <input className="input" value={selectedCustomer?.backgroundColor || ''} onChange={(e) => handleColorChange("backgroundColor", e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: selectedCustomer?.backgroundColor || '#000000'}}></div>
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="add-image-container">
                        <div className="image-container">
                            <div className="add-image" ref={dropFile}>
                                {
                                    uploadedImage?.url
                                    ? <img className="uploaded-image" src={uploadedImage.url}></img>
                                    : <img className="icon" src={addImage} />
                                }
                            </div>  
                            <p className="grey-text">Supported file types: SVG (recommended), GIF, or JPG</p>
                        </div>                                              
                        <div className="icon-container">
                            <div className="icon-box" style={{backgroundColor: 'var(--Blueberry, #2386EE)'}} onClick={handleClick}>
                                <img className="icon" src={uploadIcon} alt="Upload image" />
                                <input
                                    type="file"
                                    onChange={e => onUpload(e.target.files)}
                                    ref={fileInput}
                                    style={{display: 'none'}}
                                />
                            </div>
                            <div className="icon-box" onClick={() => setUploadedImage({
                                file: null,
                                name: null,
                                type: null,
                                url: null
                            })}>
                                <img className="icon" src={deleteIcon} alt="Delete image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="purchases-box">
                <div className="left-col">
                    <h4>Purchases and subscriptions</h4>
                    <p>This will be the legal name, address, and phone number for your primary office location or corporate registration.</p>
                    <div className="solutions-container">
                        <div className="purchase-col">
                            <h4>Solutions</h4>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('screen', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('screen') : false} />
                                <p className="pure-u-1-2">Screen</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('mobile', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('mobile') : false} />
                                <p className="pure-u-1-2">Mobile</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('foresight', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('foresight') : false} />
                                <p className="pure-u-1-2">Foresight</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('insight', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('insight') : false} />
                                <p className="pure-u-1-2">Insight</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('mobilityScore', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('mobilityScore') : false} />
                                <p className="pure-u-1-2">MobilityScore</p>
                            </div>
                        </div>
                        <div className="purchase-col">
                            <h4>Cross-solution features</h4>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('messaging', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('messaging') : false} />
                                <p className="pure-u-1-2">Playlists</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={null} checked={false} disabled />
                                <p className="pure-u-1-2">-</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={null} checked={false} disabled />
                                <p className="pure-u-1-2">-</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={null} checked={false} disabled />
                                <p className="pure-u-1-2">-</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={null} checked={false} disabled />
                                <p className="pure-u-1-2">-</p>
                            </div>
                        </div>
                        <div className="purchase-col">
                            <h4>Screen / Mobile features</h4>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('transportation', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('transportation') : false} />
                                <p className="pure-u-1-2">Transportation</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('tripPlanner', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('tripPlanner') : false} />
                                <p className="pure-u-1-2">Trip Planner</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('nearby', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('nearby') : false} />
                                <p className="pure-u-1-2">Nearby</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('directory', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('directory') : false} />
                                <p className="pure-u-1-2">Directory</p>
                            </div>
                            <div className="checkbox-container">
                                <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('wayfinding', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('wayfinding') : false} />
                                <p className="pure-u-1-2">Wayfinding</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

const OpenCustomersModal = ({ customers, selectedCustomer, handleCustomerSelection, setShowCustomersModal }) => {

	const [highlightedCustomer, sethighlightedCustomer] = useState(selectedCustomer);

	const customerRows = customers.length === 0
	? <></>
	: customers.map(customer => {

		return <tr key={customer?.id} className={`customer-row ${customer?.id === highlightedCustomer?.id ? 'selected' : ''}`} onClick={() =>sethighlightedCustomer(customer)}>
			<td>{customer?.transitScreenId}</td>
            <td>{customer?.name}</td>			
            <td>{customer?.transitScreenName}</td>
			<td>{customer?.apiKey}</td>
		</tr>
	})

    return (
        <div className="customer-modal modal">
			<div className="modal-header">
				<h2>Customers</h2>				
			</div>
			<div className="table-container">
				<table className="customers-table">
					<tbody>
						<tr className="header-row">							
							<th className="table-header">TS ID</th>
                            <th className="table-header">Name</th>
							<th className="table-header">TS Name</th>
							<th className="table-header">API Key</th>
						</tr>
						{customerRows}
					</tbody>
				</table>
			</div>
			<div className="bottom-container">
				<button className="cancel-button" onClick={() => setShowCustomersModal(false)}>Cancel</button>
				<button className="modal-button" onClick={() => handleCustomerSelection(highlightedCustomer)}>Open</button>
			</div>
        </div>
    )
}

const ManageCustomerModal = ({ selectedCustomer, setShowManageCustomerModal, handleInputChange }) => {

    return (
        <div className="manage-customer-modal modal">
			<div className="modal-header">
				<h2>Manage customer</h2>
			</div>
			<div className="license-container">
				<div className="pure-u-1-2">
                    <h4>TransitScreen ecosystem (Admin v1)</h4>
                    <div className="input-container">
						<p className="pure-u-1-4">Customer ID:</p>
						<p className="license-input">{selectedCustomer?.transitScreenId}</p>
					</div>
                    <div className="input-container">
						<p className="pure-u-1-4">Customer name:</p>
						<p className="license-input">{selectedCustomer?.transitScreenName}</p>
					</div>
					<div className="input-container">
						<p className="pure-u-1-4">"API" Key:</p>
						<input className="license-input" value={selectedCustomer?.apiKey || ''} disabled />
					</div>
				</div>
			</div>
			<div className="activation-details">
				<div className="pure-u-1-2">
                    <h4>Actionfigure ecosystem</h4>
					<div className="input-container">
						<p className="pure-u-1-5">Customer ID:</p>
						<p className="license-input">{selectedCustomer?.id}</p>
					</div>
                    <div className="input-container">
                        <p className="pure-u-1-6">Active:</p>     
                        <Switch className="switch" checked={!selectedCustomer?.isDeleted} onChange={(checked) => handleInputChange('isDeleted', !checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
				</div>
			</div>
			<div className="bottom-container">
                <button className="cancel-button" onClick={() => setShowManageCustomerModal(false)}>Cancel</button>
				<button className="modal-button" onClick={() => setShowManageCustomerModal(false)}>Ok</button>
			</div>
        </div>
    )
}

export default CustomersPage;
