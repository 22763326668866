import GreenCheck from '../../images/icons/foresight/green-check.svg';
import PoweredBy from '../../images/icons/foresight/powered-by-af.svg';

const QueueModal = ({
    queuePosition,
    estimatedTime,
    changeState
}) => {

    const formatEta = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        if (hours > 0) {
            return `${hours}h ${minutes}min`
        }
        else if (minutes > 0) {
            return `${minutes}min ${seconds}sec`;
        }
        return `${seconds}sec`;
    }

    const formatCurrentTime = () => {
        const currentTime = new Date();
        currentTime.setSeconds(currentTime.getSeconds() + estimatedTime);
        const hours = currentTime.getHours() % 12;
        const ampm = currentTime.getHours() >= 12 ? 'pm' : 'am';
        const displayHours = hours ? hours : 12;
        const currMinutes = currentTime.getMinutes() 
        const minutes = currMinutes < 10 ? '0'+currMinutes : currMinutes;
        return `${displayHours}:${minutes} ${ampm}`
    }

    const buttonClick = () => {
        changeState(['showModal'], [false]);
    }

    return (
        <div className="QueueModal">
            <img className="checkmark" src={GreenCheck} />
            <h3>Your report request has been submitted</h3>
            <p>All report requests are processed individually, in the order in which they were submitted:</p>
            <br />
            <div className="position-queue">
                <p>Your position in processing queue: &emsp;&emsp;{queuePosition}</p>
            </div>
            <br />
            <div className="eta">
                <p>Your estimated report delivery will be: &emsp;&emsp;{formatEta(estimatedTime)} ({formatCurrentTime()})</p>
            </div>
            <br />
            <div className="button-container">
                <button className="modal-button" onClick={buttonClick}>Ok</button>
            </div>
            <br />
            <img src={PoweredBy} />
        </div>
    );
}

export default QueueModal;
