import logo from '../../images/logos/af-logo.svg'

const HomeLanding = ({
	userData
}) => {

	return (
		<div className="HomeLanding">
			<div className="header pure-u-12-24 align-right">
				<img id="action-figure-logo" src={logo} alt="action figure logo"/>
			</div>
			<div className="screen-config-container">				

			</div>
        </div>
	)
}

export default HomeLanding
