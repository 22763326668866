import Select from 'react-select';

const TravelDropdown = ({
    value,
    changeState,
    propName,
    options,
    isDisabled,
    ctaButtonClicked
}) => {

    const showError = ctaButtonClicked && !value;

    const onChange = (e) => {
        changeState([propName], [e])
    }

    return (
        <div className="TravelDropdown">
            <Select  
                onChange={(e) => onChange(e)}
                options={options}
                value={value}
                className={"dropdown " + (showError ? "error" : null)}
                isDisabled={isDisabled}
            />
            {
                showError
                ? <div className="error-message">Please provide a valid input</div>
                : null
            }
        </div>
    );
}

export default TravelDropdown;
