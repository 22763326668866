import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

export default function Droppable(props) {
	const {isOver, setNodeRef} = useDroppable({
	  id: props.id,
	  data :{
		  index: props.index
	  },
	  disabled: props.disabled
	});
	const style = {
	  opacity: isOver ? 0.7 : 1,
	  position: 'relative',
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	  justifyContent: 'center',
	  borderRadius: '5px',
	  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	  background: '#fff',
	  overflow: 'hidden',
	};
  
	return (
	  <div ref={setNodeRef} style={style} className={props.className}>
		{props.children}
	  </div>
	);
};
