import ReportInput from './ReportInput';

const ReportGeneration = ({
    changeState,
    email,
    reportName,
    generateReport,
    ctaButtonClicked 
}) => {
    return (
        <div className="ReportGeneration">
            <h3>Step 5: <span style={{color:"#000"}}>Report details</span></h3>
            <div className="report-container">
                <div className="content-container pure-u-1-1">
                    <ReportInput title="Report name / label:" placeholder="What would you like this report to be called?" value={reportName} changeState={changeState} propName={'reportName'} showError={ctaButtonClicked && !reportName} />
                    <ReportInput title="Notification email:" placeholder="Who should be updated on the status of this report?" value={email} changeState={changeState} propName={'email'} showError={ctaButtonClicked && !email} />
                </div>
                <div className="content-container pure-u-1-2">
                    <div 
                        className={`button generate`}
                        onClick={generateReport}
                    >
                        Create report
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportGeneration;
