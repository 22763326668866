import React, { useState } from 'react';
import Layout from './components/Layout';
import LoginPanel from './components/Login/LoginPanel';
import { useOktaAuth } from '@okta/okta-react';

import Dashboard from './Dashboard';

import { config } from './config';

import visual1 from './images/login/login-visual1.jpg';
import visual2 from './images/login/login-visual2.jpg';
import visual3 from './images/login/login-visual3.jpg';
import visual4 from './images/login/login-visual4.jpg';
import visual5 from './images/login/login-visual5.jpg';
import visual6 from './images/login/login-visual6.jpg';
import visual7 from './images/login/login-visual7.jpg';



const Login = ({ lang }) => {
	const { oktaAuth, authState } = useOktaAuth();

	const visuals = [visual1, visual2, visual3, visual4, visual5, visual6, visual7];

	function getRandomInt(max) {
		return Math.floor(Math.random() * max);
	}

	const [backgroundImage, setBackgroundImage] = useState(visuals[getRandomInt(visuals.length)])

	const onSuccess = (tokens) => {
		oktaAuth.handleLoginRedirect(tokens);
	};

	const onError = (err) => {
		console.log('error logging in', err);
	};

	if (authState.isPending) return null;
	return authState.isAuthenticated ?
		<Layout><Dashboard lang={lang} backgroundImage={backgroundImage} /></Layout> :
		<Layout>
			<div className="Login pure-g" style={{ backgroundImage: `url(${backgroundImage})`}}>
				<LoginPanel lang={lang} className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3" />
			</div>
		</Layout>
};
export default Login;
