import { useState } from 'react';
import OfficeLocations from './OfficeLocations';
import removeIcon from '../../images/icons/foresight/trash.svg';
import plusIcon from '../../images/icons/foresight/plus.svg';
import { getDistanceFromLatLon } from '../../utils';

const LocationsSearch = ({
    changeState,
    addresses,
    addressLabels,
    ctaButtonClicked,
    searchMode
}) => {

    const [searchAreaLocation, setSearchAreaLocation] = useState(null);
    const [warningIndices, setWarningIndices] = useState([]);

    const errorIndices = addresses.map((address, index) => {
        if (index === 1) {
            return null;
        }
        return address.address === '' ? index : null
    })

    const errorLabels = addressLabels.map((label, index) => {
        if (index === 1) {
            return null;
        }
        return label === '' ? index : null
    })

    const handleAddressChanges = (val, index) => {
        let tempAddresses = [...addresses];
        tempAddresses[index] = {address: val};
        changeState(["addresses"], [tempAddresses])
    }

    const handleLabelChanges = (e, index) => {
        const val = e.target.value || '';
        let tempLabels = [...addressLabels];
        tempLabels[index] = val;
        changeState(["addressLabels"], [tempLabels])
    }

    const handleResultSelect = (location, index) => {
        let tempAddresses = [...addresses];
        tempAddresses[index] = location;

        if (index === 0) {
            let tempWarnings = []
            //Check through each one
            tempAddresses.forEach((address, index) => {
                if (index === 0) return;
                if (getDistanceFromLatLon(location, address) > 100) {
                    tempWarnings.push(index) 
                }
            });
            setWarningIndices(tempWarnings);
        }
        //Set warning
        else if (getDistanceFromLatLon(searchAreaLocation, location) > 100) {
            const tempWarnings = [...warningIndices];
            setWarningIndices([...tempWarnings, index]);
        }
        //Remove warning
        else if (warningIndices.includes(index)) {
            let tempWarnings = [...warningIndices];
            const removeIndex = tempWarnings.indexOf(index);
            tempWarnings.splice(removeIndex, 1);
            setWarningIndices(tempWarnings);
        }

        changeState(["addresses"], [tempAddresses])
    }

    const removeAddress = (index) => {  
        let tempAddresses = [...addresses];
        let tempLabels = [...addressLabels];
        //If first multi address, just delete items and not remove entire row
        if (index === 1) {
            tempAddresses[1] = {name: '', address: ''};
            tempLabels[1] = ''
        }
        else {
            tempAddresses.splice(index, 1);
            tempLabels.splice(index, 1);
        }
        changeState(["addresses", "addressLabels"], [tempAddresses, tempLabels]);
        return;
    }

    return (
        <div className="LocationsSearch">
            <div className="container"> 
                {
                    searchMode === 'current'
                    ? <SingleLocation
                        setSearchAreaLocation={setSearchAreaLocation}
                        handleLabelChanges={handleLabelChanges}
                        handleAddressChanges={handleAddressChanges}
                        handleResultSelect={handleResultSelect}
                        addresses={addresses}
                        addressLabels={addressLabels}
                        ctaButtonClicked={ctaButtonClicked}
                        errorIndices={errorIndices}
                        errorLabels={errorLabels}
                    />
                    : <MultiLocation
                        changeState={changeState}
                        setSearchAreaLocation={setSearchAreaLocation}
                        handleLabelChanges={handleLabelChanges}
                        handleAddressChanges={handleAddressChanges}
                        handleResultSelect={handleResultSelect}
                        addresses={addresses}
                        addressLabels={addressLabels}
                        removeAddress={removeAddress}
                        ctaButtonClicked={ctaButtonClicked}
                        errorIndices={errorIndices}
                        warningIndices={warningIndices}
                        errorLabels={errorLabels}
                    />
                }
            </div>
        </div>
    );
}

const SingleLocation = ({ setSearchAreaLocation, handleLabelChanges, handleAddressChanges, handleResultSelect, addresses, addressLabels, ctaButtonClicked, errorIndices, errorLabels }) => {

    return <div className="locations-container">
        <h3 style={{color:"var(--Blueberry, #2386EE)"}}>Step 2: <span style={{color:"#000"}}>Provide your current office’s address</span></h3>
        <div className="inputs-container">
            <OfficeLocations
                setSearchAreaLocation={setSearchAreaLocation}
                handleAddressChanges={handleAddressChanges}
                handleResultSelect={handleResultSelect}
                placeholder='Specify current location'
                value={addresses[0].address || ''}
                propName={'addresses'}
                labelPropName={'currentAddressLabel'}
                index={0}
                showError={ctaButtonClicked && errorIndices.includes(0)}
            />
            <div className={`office-label-container`}>
                <input className={`officeLabel ${ctaButtonClicked && errorLabels.includes(0) ? "error" : ""}`} placeholder="Enter a label / name for this location" value={addressLabels[0]} onChange={(e) => handleLabelChanges(e, 0)} />
            </div>
            <div className="icon-container" style={{visibility:"hidden"}}>
                <img className="remove-icon" src={removeIcon}/>
            </div>
            <div className="icon-container" style={{visibility:"hidden"}}>
                <img className="plus-icon" src={plusIcon}/>
            </div>
        </div>
    </div>
}

const MultiLocation = ({ changeState, setSearchAreaLocation, handleLabelChanges, handleAddressChanges, handleResultSelect, addresses, addressLabels, removeAddress, ctaButtonClicked, errorIndices, warningIndices, errorLabels }) => {
    
    const [addressLimitExceed, setAddressLimitExceed] = useState(false);

    const address = addresses?.map((address, index) => {
        if (index === 0) return;
        return <div key={index}>
            <div className="inputs-container">
                <OfficeLocations
                    key={index}
                    setSearchAreaLocation={setSearchAreaLocation}
                    handleAddressChanges={handleAddressChanges} 
                    handleResultSelect={handleResultSelect}
                    placeholder='Specify prospective location' 
                    value={address.address || ''} 
                    propName={'addresses'} 
                    labelPropName={'currentAddressLabel'} 
                    index={index}
                    showError={ctaButtonClicked && errorIndices.includes(index)}
                />
                <div className={`office-label-container`}>
                    <input 
                        className={`additional-office ${ctaButtonClicked && errorLabels.includes(index) ? "error" : ""}`}
                        key={index + " label"}
                        placeholder="Enter a label / name for this location" 
                        value={addressLabels[index]} 
                        onChange={(e) => handleLabelChanges(e, index)} 
                        index={index}
                    />
                </div>
                <div className="icon-container">
                    <img className="remove-icon" src={removeIcon} onClick={() => removeAddress(index)} />
                </div>
                <div className="icon-container">
                    <img className="plus-icon" src={plusIcon} onClick={() => addAnother(index)} />
                </div>
            </div>
            {
                warningIndices.includes(index)
                ? <p className="warning-message">Address is 100+ miles from the current location you specified. Proceeding may result in data anomalies or gaps.</p>
                : <></>
            }
        </div>
    })

    const addAnother = () => {
        if (addresses.length >= 5) {
            setAddressLimitExceed(true);
            return;
        }
        changeState(["addresses", "addressLabels"], [[...addresses, {name: '', address: ''}], [...addressLabels, '']]);
    }

    return <div className="multi-locations-container">
        <h3 style={{color:"var(--Blueberry, #2386EE)"}}>Step 3: <span style={{color:"#000"}}>Specify any prospective office addresses (optional)</span></h3>
        <p>Foresight can compare your current location against up to 4 prospective locations.</p>
        {address}
        {/* <div className="add-another">
            <p onClick={addAnother}>+ Add another</p>
            {
                addressLimitExceed
                ? <p className="max-address">You may provide maximum 4 addresses</p>
                : <></>
            }
        </div> */}
    </div>
}

export default LocationsSearch;
