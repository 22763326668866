import { useCallback, useEffect, useRef, useState } from 'react';

const mobUrl = process.env.REACT_APP_MOB_URL + "/mobilityscore/v1/score.json?coordinates={coords}&key=" + process.env.REACT_APP_MOB_API_KEY;

async function getMobilityScore(coords) {
  var queryUrl = mobUrl.replace("{coords}", coords);
  let response = await fetch(queryUrl);
  let data = await response.json();

  return data;
}

/*
 * Get the score from the MobilityScore API and display results
 */
function getScore(place) {
  // A spinner gif to tell end user we're working on it
  var spinnerElement = document.getElementById("spinner");
  spinnerElement.classList.add("mob_spinner");

  var latitude = place.geometry.location.lat();
  var longitude = place.geometry.location.lng();
  var coordinates = latitude + ',' + longitude;
  getMobilityScore(coordinates)
    .then((response) => {

      var scoreElement = document.getElementById("score");
      scoreElement.innerHTML = response.data.score;

      // Re-display the chosen address
      var addressElement = document.getElementById("address");
      addressElement.innerHTML = place.formatted_address;
      addressElement.classList.add("mob_linkLength");
      // "Copy Text" button
      var addressCopyElement = document.getElementById("address_copy");
      addressCopyElement.setAttribute("data-value", place.formatted_address);
      addressCopyElement.addEventListener("click", copyText);

      // Display the map link
      var mapLinkElement = document.getElementById("map_link")
      mapLinkElement.innerHTML = response.data.mapUrl;
      mapLinkElement.classList.add("mob_linkLength");
      // "Copy Link" button (map)
      var mapCopyElement = document.getElementById("map_copy");
      mapCopyElement.setAttribute("data-value", response.data.mapUrl);
      mapCopyElement.addEventListener("click", copyText);
      // Anchor tag should have correct URLs
      var anchor = document.getElementById("map_open");
      anchor.setAttribute("href", response.data.mapUrl);
      anchor.setAttribute("target", "_blank");

      // PNG info
      var pngLinkElement = document.getElementById("png_link");
      pngLinkElement.innerHTML = response.data.badgeUrlPng;
      pngLinkElement.classList.add("mob_linkLength");
      // "Copy Link" (PNG)
      var pngCopyElement = document.getElementById("png_copy");
      pngCopyElement.setAttribute("data-value", response.data.badgeUrlPng);
      pngCopyElement.addEventListener("click", copyText);
      // Anchor tag should have correct URLs
      anchor = document.getElementById("png_open");
      anchor.setAttribute("href", response.data.badgeUrlPng);
      anchor.setAttribute("target", "_blank");

      // SVG info
      var svgLinkElement = document.getElementById("svg_link");
      svgLinkElement.innerHTML = response.data.badgeUrl;
      svgLinkElement.classList.add("mob_linkLength");
      // "Copy Link" (SVG)
      var svgCopyElement = document.getElementById("svg_copy");
      svgCopyElement.setAttribute("data-value", response.data.badgeUrl);
      svgCopyElement.addEventListener("click", copyText);
      // Anchor tag should have correct URLs
      anchor = document.getElementById("svg_open");
      anchor.setAttribute("href", response.data.badgeUrl);
      anchor.setAttribute("target", "_blank");

      spinnerElement.classList.remove("mob_spinner");
    });
}

const copyText = (element) => {
  var textToCopy = element.target.getAttribute("data-value");
  navigator.clipboard.writeText(textToCopy);
}

const MobilityScore = ({
  lang,
  userData
}) => {

  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const options = {
    fields: ["address_components", "formatted_address", "geometry", "name"]
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
    var listenerHandle = autoCompleteRef.current.addListener('place_changed', placeChangedHandler);
    // Remove the listener through its handle https://stackoverflow.com/a/1544219/1021224
    return () => {window.google.maps.event.removeListener(listenerHandle);}
  });

  const placeChangedHandler = useCallback(async () => {
    var place = await autoCompleteRef.current.getPlace();
    // If Google Maps API returned data:
    if (typeof place === 'object' && place?.geometry !== undefined) {
      getScore(place);
    }
  }, [autoCompleteRef]);

  return (
    <div id="mobilityscore">
      <h1>MobilityScore</h1>
      <div>
        <div className={"mob_label"}>Enter an address</div>
        <div className={"mob_rowSpacing"}><input name="address" type="text" label="Address" id="addressInput" ref={inputRef} /></div>
      </div>

      <div id="mobilityscore_section" className="">
        <div className="mob_header">
          <h3 id="results">Results</h3>
          <div id="spinner"></div>
        </div>
        <div id="score_info">
          <div className="mob_label score_label">Score</div>
          <div id="score" className="mob_rowSpacing"></div>
        </div>
        <div id="address_info">
          <div className="mob_label">Address</div>
          <div id="address" className="mob_rowSpacing"></div>
          <div id="address_copy" className="mob_rowSpacing mob_buttonStyle">Copy Text</div>
        </div>
        <div id="map_info">
          <div className="mob_label">Map</div>
          <div id="map_link" className="mob_rowSpacing"></div>
          <div id="map_copy" className="mob_rowSpacing mob_buttonStyle">Copy Link</div>
          <div className="mob_rowSpacing mob_buttonStyle">
            <a className="mob_plainAnchorTag" id="map_open">Open Link</a>
          </div>
        </div>
        <div id="png_info">
          <div className="mob_label">Score PNG</div>
          <div id="png_link" className="mob_rowSpacing"></div>
          <div id="png_copy" className="mob_rowSpacing mob_buttonStyle">Copy Link</div>
          <div className="mob_rowSpacing mob_buttonStyle">
            <a className="mob_plainAnchorTag" id="png_open">Open Link</a>
          </div>
        </div>
        <div id="svg_info">
          <div className="mob_label">Score SVG</div>
          <div id="svg_link" className="mob_rowSpacing"></div>
          <div id="svg_copy" className="mob_rowSpacing mob_buttonStyle">Copy Link</div>
          <div className="mob_rowSpacing mob_buttonStyle">
            <a className="mob_plainAnchorTag" id="svg_open">Open Link</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobilityScore