
const getBasePlaylists = async (customerId) => {
    const playlists = await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/getBasePlaylists/${customerId}`)
    .then(response => response.json())
    .then(data => {
        return data.map(d => {
            if (d.playlist.product === 'screen' || d.playlist.product === 'screen_pro') {
                return {
                    ...d.playlist,
                    url: d?.slide?.url,
                    devices: d?.devices
                }
            }
            return;
        }).filter(n => n)
    })
    return playlists
}

const getTimezone = async (lat, long) => {
    const timezone = await fetch(`https://timezones.transitscreen.io/getTimezoneByCoordinates.php?apiKey=${process.env.REACT_APP_TIMEZONE_API_KEY}&lat=${lat}&lon=${long}`)
    .then(response => response.json())
    .then(data => data.tzid);

    return timezone;
}

const getContentPlaylists = async (customerId) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/getContentPlaylists/${customerId}`;
    return await fetch(url)
    .then(response => response.json())
    .then(data => {
        return data
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const savePlaylist = async (state) => {

    const playlist = {
        ...state.playlist,
        language: state?.playlist?.language?.value
    }

    //Temporary key setting while most of our customers have nothing
    let url = `${process.env.REACT_APP_CITYMO_URL}/?key=kDgUcNxt25pUpxy8ivQ5UYSvXc4dF0acwzLCLu3wMHa0rDZLGDcm3rsZ2LlMjsjs&coordinates=${playlist?.latitude},${playlist?.longitude}`;
    const transportationConfigs = state.transportationConfigs;
    let count = 0;

    for (let key in transportationConfigs) {
        let value = transportationConfigs[key].toString();

        if (key !== 'key' && key !== 'coordinates' && key !== 'environment' && key !== 'context' && value) {
            url += `&${key}=${value.replace(/\s/g, '')}`;
            count++
        }
    }

    //Create or update depending on playlist
    const bizUrl = playlist?.id 
    ? `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/savePlaylist`
    : `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/createBasePlaylist`    
   
    return await fetch(bizUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
            url: url,
            playlist: playlist
        })
    })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

export {
    getBasePlaylists,
    getTimezone,
    getContentPlaylists,
    savePlaylist,
}
