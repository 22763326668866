const saveCustomer = async (customer) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/updateCustomer`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // "Authorization": "Bearer " + token
        },
        body: JSON.stringify(customer)
    })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

export {
    saveCustomer
}
