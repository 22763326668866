import React, { useEffect } from 'react';

// import { getSessionStorage, setSessionStorage } from './SessionStorage';

const Context = React.createContext(
	[{}, () => { }],
);

const DEFAULT_STATE = {	
	customer: {}, //AccountPage.js
	userData: {},
	//TODO Add once events are more fleshed out and coming from BIZ
	// events: {},
};

const Provider = (props) => {
	const [state, setState] = React.useState(DEFAULT_STATE);
	return (
		<Context.Provider value={[state, setState]}>
			{props.children}
		</Context.Provider>

	);
};

export {
	Context,
	Provider,
};