import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import logo from '../../images/logos/af-logo.svg'
import TravelSettings from './TravelSettings';
import LocationsSearch from './LocationsSearch';
import EmployeesAddress from './EmployeesAddress';
import ReportGeneration from './ReportGeneration';
import QueueModal from './QueueModal';
import { sendDataToJourneyBatch, postToDataDrop, createReport } from '../../utils';

const ForesightPage = ({
	userData,
	customer
}) => {
	//In case of re-run from reports & analytics
	const location = useLocation();
	const userInputs = location.userInputs;

	const [state, setState] = useState({
		accessToken: userData.accessToken,
		tokenExpirationTime: null,
		apiKey: '',
		isDisabled: true,
		selectedDay: userInputs?.selectedDay ? userInputs?.selectedDay : { value: 'wed', label: 'Wednesday (default)' },
		isFromOffice: userInputs?.isFromOffice ? userInputs?.isFromOffice : { value: 'homeToOffice', label: 'Home to office (default)' },
		travelTime: userInputs?.travelTime ? userInputs?.travelTime : { value: '9:00am', label: '9:00am (default)' },
		addresses: userInputs?.addresses ? userInputs?.addresses : [{name: '', address: ''}, {name: '', address: ''}],
		addressLabels: userInputs?.addressLabels ? userInputs?.addressLabels : ['', ''],
		employeeAddresses: userInputs?.employeeAddresses ? userInputs?.employeeAddresses : null,
		//Realized at this point there is no need to actually re-download or re-create the uploaded file
		//If this is a re-run, just show the file name, as there is no way for the user to re-download it here
		uploadedFile: userInputs?.employeeAddresses ? new File(["Redo"], userInputs?.fileName || 'Uploaded file') : null,
		email: userInputs?.email ? userInputs?.email : '',
		organizationName: customer.name,
		organizationId: customer.id,
		reportName: userInputs?.reportName ? `Rerun - ${userInputs?.reportName}` : '',
		model:  { value: 'insight', label: 'Insight (recommended)' },
		ctaButtonClicked: false,
		error: false,
		queuePosition: 0,
		estimatedTime: 0,
		showModal: false,
	  });

	  const changeState = (propNames, values) => {
		let tempState = {
		  ...state
		}
		
		propNames.forEach((item, index) => {
		  tempState[item] = values[index];
		});
	
		// tempState[propName] = newValue;
		setState(tempState);
	  }
	
	  const checkErrors = () => {
		return !state.travelTime
		|| !state.isFromOffice
		|| !state.selectedDay
		|| !state.email
		|| !state.employeeAddresses
		|| !state.reportName
		|| !(state.addresses[0] !== '' && state.addressLabels[0] !== '')
	  }
	
	  const generateReport = async () => {
	
		const errorExists = checkErrors();
	
		if (errorExists) {
		  setState({
			...state,
			ctaButtonClicked: true
		  })
		}
		else {
		  const reportId = uuidv4();
		  const result = await sendDataToJourneyBatch(state, reportId);
	
		  setState({
			...state,
			ctaButtonClicked: true,
			queuePosition: result.data.position,
			estimatedTime: result.data.estimatedTime,
			showModal: true
		  })

		  setTimeout(async () => {
			const data = {
				apiKey: state.apiKey,
				processingTime: result.data.estimatedTime,
				email: state.email,
				reportName: state.reportName
			  };

			  await createReport(state, userInputs, reportId, state.reportName, result.data.estimatedTime, userData.name);
			  await postToDataDrop(data, 'submit', state.accessToken, state.tokenExpirationTime, result, changeState);	
		  }, 5000); //Give time for state to update properly
		}
	}

	return ( 
		<div className="ForesightPage">
			{
				state.showModal
				? <QueueModal 
					queuePosition={state.queuePosition}
					estimatedTime={state.estimatedTime}
					changeState={changeState}
				/>
				: <></>
			}
			<div className="foresight-header pure-u-12-24 align-right">
				<img id="action-figure-logo" src={logo} alt="action figure logo"/>
			</div>
			<div className="foresight-container">
				<TravelSettings 
					changeState={changeState} 
					isFromOffice={state.isFromOffice}
					selectedDay={state.selectedDay}
					travelTime={state.travelTime}
					ctaButtonClicked={state.ctaButtonClicked}
				/>
				<LocationsSearch 
					changeState={changeState}
					addresses={state.addresses}
					addressLabels={state.addressLabels}
					ctaButtonClicked={state.ctaButtonClicked}
					searchMode="current"
				/>
				<LocationsSearch 
					changeState={changeState}
					addresses={state.addresses}
					addressLabels={state.addressLabels}
					ctaButtonClicked={state.ctaButtonClicked}
					searchMode="prospective"
				/>
				<EmployeesAddress 
					changeState={changeState}
					employeeAddresses={state.employeeAddresses}
					ctaButtonClicked={state.ctaButtonClicked}
					searchCountry={state.addresses[0]?.country}
					uploadedFile={state.uploadedFile}
				/>
				<ReportGeneration 
					changeState={changeState}
					email={state.email}
					reportName={state.reportName} 
					generateReport={generateReport}
					ctaButtonClicked={state.ctaButtonClicked}
				/>
			</div>
		</div>
	);
}

export default ForesightPage;
