import { useState, useEffect } from 'react';
import logo from '../../images/logos/af-logo.svg'

const TempPlaylistCreationPage = ({
	userData
}) => {
    
    const [playlistName, setPlaylistName] = useState('');
    const [shortCode, setShortCode] = useState((Math.random() + 1).toString(36).substring(2,7));
    const [slides, setSlides] = useState([{
        slideName: '',
        slideUrl: '',
        slideOrder: 1,
        slideDuration: 1000
    }]);
    const [message, setMessage] = useState('');

    const changeSlideValue = (property, index, value) => {
        const tempSlides = [...slides];
        tempSlides[index][property] = value;
        setSlides(tempSlides);
        return;
    }

    const addSlide = () => {
        const tempSlides = [...slides];
        tempSlides.push({
            slideName: '',
            slideUrl: '',
            slideOrder: slides.length + 1,
            slideDuration: 1000
        })
        setSlides(tempSlides);
    }

    const onSubmit = async () => {
        const body = {
            customerId: userData.customerId,
            playlistName,
            shortCode,
            slides
        }

        await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/createTempPlaylist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        .then((response) => {
            setMessage(`Successfully created with short Code ${shortCode}`);
            setShortCode((Math.random() + 1).toString(36).substring(2,7));
            return response;
        })
        .catch((error) => {
            setMessage('Could not create Playlist');
        });
        return;
    }

	return (
		<div className="TempPlaylistCreationPage">
			<div className="page-name pure-u-12-24 align-right">
				<img id="action-figure-logo" src={logo} alt="action figure logo"/>
			</div>

            <div className="playlist-container">
				<h2>Create Your Playlist</h2>
                <p>Short Code: {shortCode}</p>
                <label htmlFor="pl-name">Playlist Name</label>
                <input type="text" id="pl-name" onChange={(e) => setPlaylistName(e.target.value)} value={playlistName}></input>
                <br />
                <button className="add-button" onClick={() => addSlide()}>Add Slide</button>
                {
                    slides.map((slide, index) => {
                        return <div className="slide-container" key={index}>
                            <h3>Slide {index + 1}</h3>
                            <label htmlFor={`slide-${index}-name`}>Slide {index + 1} Name</label>
                            <input type="text" id={`slide-${index}-name`} value={slides[index].slideName} onChange={(e) => changeSlideValue("slideName", index, e.target.value)}></input>
                            <label htmlFor={`slide-${index}-url`}>Slide {index + 1} Url</label>
                            <input type="text" id={`slide-${index}-url`} value={slides[index].slideUrl} onChange={(e) => changeSlideValue("slideUrl", index, e.target.value)}></input>
                            <label htmlFor={`slide-${index}-order`}>Slide {index + 1} Order</label>
                            <input type="text" id={`slide-${index}-order`} value={slides[index].slideOrder} onChange={(e) => changeSlideValue("slideOrder", index, e.target.value)}></input>
                            <label htmlFor={`slide-${index}-duration`}>Slide {index + 1} Duration</label>
                            <input type="text" id={`slide-${index}-duration`} value={slides[index].slideDuration} onChange={(e) => changeSlideValue("slideDuration", index, e.target.value)}></input>
                        </div>
                    })
                }
                <button className="submit-button" onClick={() => onSubmit()}>Create Playlist</button>
                <p>{message}</p>
			</div>
			
        </div>
	)
}

export default TempPlaylistCreationPage
