import React, {
    useState
} from 'react';

import { findGmapsCounty, findGmapsCountry } from '../../utils';

const AddressSearch = ({
    handleAddressChanges,
    handleResultSelect,
    placeholder, 
    value,
    propName,
    disabled
}) => {

    const [isActive, setIsActive] = useState(null);
    const [searchResults, setResults] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);

    function onFocusEvent(e) {
        setIsActive(true);
    }

    async function search(searchString) {
        return new Promise(async (resolve, reject) => {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${searchString}&key=${process.env.REACT_APP_GMAPS_KEY}`

            return fetch(url)
            .then(addresses => addresses.json())
            .then(results => {
                if (results.status === 'OK') {
                    const newResults = results.results.slice(0,5).map((result) => {
                        const county = findGmapsCounty(result.address_components);
                        const zip = result.address_components.find(item => item.types.includes('postal_code'))
                        return {
                            name: result.formatted_address.split(',')[0],
                            address: result.formatted_address,
                            latitude: result.geometry?.location?.lat || null,
                            longitude: result.geometry?.location?.lng || null,
                            county: county?.long_name,
                            zip: zip?.long_name,
                            country: findGmapsCountry(result.address_components)?.short_name
                        }
                    });
                    setResults(newResults);
                    resolve(newResults)
                }
                return;
            })
        })
    }

    async function onChangeEvent(e) {
        clearTimeout(searchTimeout); 
        const val = e.target.value || '';
        handleAddressChanges(propName, val)

        if (!val) return null; // The user has zeroed out the field, keeps the last search results up
        setIsActive(true);
        try {
            setSearchTimeout(setTimeout(async() => {
                await search(val);
            }, 1000))
            
        } catch (e) {
            console.error(e);
        }
    }

    function onSelect(location) {
        handleResultSelect(location)        
        setIsActive(false);
        setResults(null);
    }

    function Results({ searchResults }) {
        if (!searchResults) return null;
        return searchResults.map((result, i) => {
            if (!result) return null;
            return (
                <div className="results" key={i}>
                    <button className="select-item" onClick={() => onSelect(result)}>
                        {result.name}
                        <div className="select-item-address">{result.address}</div>
                    </button>
                </div>
            )
        });
    }

    return (
        <div className="AddressSearch">
            <div className="search-container">
                <input
                    className={`search ${disabled ? "disabled" : ""}`}
                    type="search"
                    value={value}
                    placeholder={placeholder}
                    onFocus={onFocusEvent}
                    onChange={(e) => { onChangeEvent(e)} }
                    autoComplete="off"
                    id="type-search"
                    data-lpignore="true"
                    v-model="filter"
                    data-1p-ignore={true}
                    disabled={disabled}
                    />
                <div className="results-container">
                    <Results searchResults={searchResults} />
                </div>
            </div>
        </div>
    );
}

export default AddressSearch;
