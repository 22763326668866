import {
	Route,
	Switch,
	Link,
} from 'react-router-dom';

import LoginForm from './LoginForm'
import ForgotPassword from './ForgotPassword'

// import tsLogo from '../../images/logos/ts-main-logo.png'
import afLogo from '../../images/logos/af-main-logo.svg'

export default function LoginPanel({
	className,
	lang
}) {

	return (
		<div className={"LoginPanel " + className}>

			<div className="LoginArea">
				<div className="inner-centering">
					<div className="CompanyLogo">
						<Link to="/"><img width={350} height={350} src={afLogo} /></Link>
					</div>

					<Switch>
						<Route path="/forgot-password"><ForgotPassword lang={lang} /></Route>
						<Route path="/"><LoginForm lang={lang} /></Route>
					</Switch>

	            </div>
			</div>

			<div className="ContactSupport">
				Having issues? <a href="mailto:support@transitscreen.com">Contact support.</a>
			</div>
        </div>
	)
}
