import React, {
	useState,
	useEffect
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ThreeDots } from 'react-loader-spinner';

import { config } from '../../config';

export default function LoginForm({lang}) {
	const { oktaAuth } = useOktaAuth();
	const [sessionToken, setSessionToken] = useState();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [cookies, setCookie, removeCookie] = useCookies([config.oktaUserCookieName, config.rememberUserLoginCookieName]);

	const [showLoginNotices, enableLoginNotices] = useState(null);

	const [rememberUser, setRememberUser] = useState(true);

	useEffect(() => {
		if (cookies[config.rememberUserLoginCookieName]) {
			setUsername(cookies[config.rememberUserLoginCookieName]);
		}
	}, []);

	const submitLogin = (e) => {
		e.preventDefault();
		oktaAuth.signInWithCredentials({ username, password })
			.then(res => {
				// Okta needs this cookie
				setCookie(config.oktaUserCookieName, res.user);

				// Remember user
				setCookie(config.rememberUserLoginCookieName, username);

				const sessionToken = res.sessionToken;
				setSessionToken(sessionToken);
				// sessionToken is a one-use token, so make sure this is only called once
				oktaAuth.signInWithRedirect({ sessionToken });
			})
			.catch(err => {
				enableLoginNotices(true);
				console.log('Found an error', err);
			});
	}

	const handleUsernameChange = (e) => {
		setUsername(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const toggleRememberUser = (e) => {
		const doRemember = !rememberUser;

		console.log("Toggled", username, doRemember);
		if (doRemember) {
			setCookie(config.rememberUserLoginCookieName, username);
		} else {
			removeCookie(config.rememberUserLoginCookieName);
		}

		setRememberUser(doRemember);
	}

	if (sessionToken) {
		// Hide form while sessionToken is converted into id/access tokens		
		return <ThreeDots
			visible={true}
			height="80"
			width="80"
			color="#2386EE"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{}}
			wrapperClass="Loading"
		/>;
	}

	// console.log(rememberUser, toggleState);
	return (
		<div className="LoginForm">

			<form className={showLoginNotices && "FormLoginFailed"}>

				{showLoginNotices && <p>* {lang['login3']}</p>}

				<div className="field">
			    	<label>Username</label>
			    	<input id="emailAddress" type="email" value={username} onChange={handleUsernameChange} autoComplete="username" />
		    	</div>

				<div className="field">
			    	<label>Password</label>
			    	<input id="password" type="password" value={password} onChange={handlePasswordChange} autoComplete="current-password" />
					<i className="bi bi-eye-slash" id="togglePassword"></i>
		    	</div>

		    	<input onClick={submitLogin} type="submit" className="button" type="submit" value={lang['login1']} />
	    	</form>

	    	<div className="pure-g">
	    		<div className="pure-u-1-2"><button className="remember-user" onClick={toggleRememberUser}>
					<input type="checkbox" readOnly checked={rememberUser} />Remember me</button>
				</div>
	    		<div className="pure-u-1-2 align-right"><Link to="/forgot-password">Forgot password?</Link></div>
			</div>

		</div>
	)
	// Not signed in <br/> <a href="/api/auth/signin">Sign in</a>
}
