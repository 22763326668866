import TravelDropdown from './TravelDropdown';

const TravelSettings = ({
    changeState,
    isFromOffice,
    selectedDay,
    travelTime,
    ctaButtonClicked
}) => {

    const showError = ctaButtonClicked
    && !selectedDay

    return (
        <div className="TravelSettings">
            <h3>Step 1: <span style={{color:"#000"}}>Choose day(s) and time of travel</span></h3>
            <div className="container">
                <AdvancedSetting
                    isFromOffice={isFromOffice}
                    selectedDay={selectedDay}
                    travelTime={travelTime}
                    changeState={changeState}
                    ctaButtonClicked={ctaButtonClicked}
                    showError={showError}
                />                     
            </div>
        </div>
    );
}

const AdvancedSetting = ({ isFromOffice, selectedDay, travelTime, changeState, ctaButtonClicked, showError }) => {
    return <div className="travel-container">                         
        <div className="weekDays-container">
            <div className="text-container">
                <p>Actionfigure Foresight will analyze all transportation routes, schedules, and availability based on the day(s) and time you select.</p>
            </div>
            
            <div className="days-container">
                <TravelDropdown 
                    value={isFromOffice} 
                    changeState={changeState} 
                    propName={'isFromOffice'} 
                    options={[
                        { value: 'homeToOffice', label: 'Home to office (default)' },
                        { value: 'officeToHome', label: 'Office to home' }
                    ]}
                    isDisabled={false}
                    ctaButtonClicked={ctaButtonClicked}
                />
                <p>on</p>
                <TravelDropdown 
                    value={selectedDay} 
                    changeState={changeState} 
                    propName={'selectedDay'} 
                    options={[
                        { value: 'sun', label: 'Sunday' },
                        { value: 'mon', label: 'Monday' },
                        { value: 'tue', label: 'Tuesday' },
                        { value: 'wed', label: 'Wednesday (default)' },
                        { value: 'thu', label: 'Thursday' },
                        { value: 'fri', label: 'Friday' },
                        { value: 'sat', label: 'Saturday' },
                    ]}
                    isDisabled={false}
                    ctaButtonClicked={ctaButtonClicked}
                />
                <p>at</p>
                <TravelDropdown 
                    value={travelTime} 
                    changeState={changeState} 
                    propName={'travelTime'} 
                    options={[
                        { value: '12:00am', label: '12:00am' },
                        { value: '1:00am', label: '1:00am' },
                        { value: '2:00am', label: '2:00am' },
                        { value: '3:00am', label: '3:00am' },
                        { value: '4:00am', label: '4:00am' },
                        { value: '5:00am', label: '5:00am' },
                        { value: '6:00am', label: '6:00am' },
                        { value: '7:00am', label: '7:00am' },
                        { value: '8:00am', label: '8:00am' },
                        { value: '9:00am', label: '9:00am (default)' },
                        { value: '10:00am', label: '10:00am' },
                        { value: '11:00am', label: '11:00am' },
                        { value: '12:00pm', label: '12:00pm' },
                        { value: '1:00pm', label: '1:00pm' },
                        { value: '2:00pm', label: '2:00pm' },
                        { value: '3:00pm', label: '3:00pm' },
                        { value: '4:00pm', label: '4:00pm' },
                        { value: '5:00pm', label: '5:00pm' },
                        { value: '6:00pm', label: '6:00pm' },
                        { value: '7:00pm', label: '7:00pm' },
                        { value: '8:00pm', label: '8:00pm' },
                        { value: '9:00pm', label: '9:00pm' },
                        { value: '10:00pm', label: '10:00pm' },
                        { value: '11:00pm', label: '11:00pm' }
                    ]}
                    isDisabled={false}
                    ctaButtonClicked={ctaButtonClicked}
                />
            </div>
            {
                showError
                ? <p className="error-message">At least one day must be selected</p>
                : <></>
            }            
        </div>
    </div>   
}

export default TravelSettings;
