import React from 'react';
import SidebarMenu from './SidebarMenu';
import actionfigureLogo from '../../images/logos/af-main-logo.svg';
import lock from '../../images/icons/menu/lock.svg';

const Sidebar = ({
    className,
    lang,
    setModal,
    adminMenu,
    customer
}) => {

    return (
        <div className={`Sidebar ${className || ''}`}>

            <div className="container">
                <div className="User">
                    {/* <img src={actionfigureLogo}></img> */}
                    <div className="Photo" dangerouslySetInnerHTML={{ __html: `${customer.customerLogo}` }}/>
                </div>

                <SidebarMenu setModal={setModal} enabledNavs={customer.enabledDash2Navs} lang={lang} adminMenu={adminMenu} />

                <div className="contact-support">
                    <img src={actionfigureLogo}></img>
                    <div className="privacy-policy">
                        <p>View our <a href="https://www.iubenda.com/privacy-policy/92358828">privacy policy</a></p>
                        &nbsp;<img src={lock}></img>
                    </div>
                </div>

            </div>

        </div>
    )
}


export default Sidebar;
