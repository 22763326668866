import { useState, useEffect, useRef } from 'react';
import { Circles } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import {getSlideUploadUrl, uploadSlideToAws, createSlide, updateSlide, removeSlide } from './controller';
import addImage from '../../images/icons/add-image.svg';
import brushIcon from '../../images/icons/brush.svg';
import deleteIcon from '../../images/icons/analytics/trash.svg';
import deleteDisabledIcon from '../../images/icons/analytics/trash-disabled.svg';
import uploadIcon from '../../images/icons/upload.svg';
import templateIcon from '../../images/icons/template.svg';

export default function SlideModal({ setShowModal, customerId, slideToEdit, setScrollRight, playlists }) {
	const [uploadedImage, setUploadedImage] = useState({
		file: null,
		name: null,
		type: null,
		url: null
	});
	const [slideName, setSlideName] = useState('');
	const [slidePlaylists, setSlidePlaylists] = useState([]);
	const [slide, setSlide] = useState(slideToEdit);
	const [typeError, setTypeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const dropFile = useRef(null);
	const fileInput = useRef(null);
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('');
	const [enabledButtons, setEnabledButtons] = useState(false);

	useEffect(() => {
		if (slideToEdit) {
			setSlideName(slideToEdit.label);
			var urlParts = slideToEdit.url.split('.');
			setUploadedImage({...uploadedImage, name: slideToEdit.label, url: slideToEdit.url, type: urlParts.pop()});

			if (playlists.length > 0) {
				var currentSlidePlaylists = [];
				playlists.forEach((playlist, index) => {
					playlist.playlistSlides.forEach(pSlide => {
						if (pSlide.slideId === slideToEdit.id) {
							currentSlidePlaylists.push(playlists[index]);
						}
					});
				});
				setSlidePlaylists(currentSlidePlaylists);
			}
		}
	}, [slideToEdit]);

	useEffect(() => {
		//Add event lister for file upload
		dropFile.current.addEventListener('dragover', handleDragOver);
		dropFile.current.addEventListener('drop', handleDrop);
	  
		return () => {
			dropFile.current.removeEventListener('dragover', handleDragOver);
			dropFile.current.removeEventListener('drop', handleDrop);
		};
	}, []);

	const onUpload = async (upload) => {
		const file = upload[0];
		//Only accept image types jpeg/jpg and gif
		if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/gif') {
			setTypeError(true);
			return;
		}
		const objectUrl = URL.createObjectURL(file);
		setUploadedImage({
			file: file,
			name: file.name,
			type: file.type.split('/')[1],
			url: objectUrl
		});
		setTypeError(false);
		setEnabledButtons(true);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	  
		const {files} = e.dataTransfer;
	  
		if (files && files.length) {
		  onUpload(files);
		}
	};

	const handleClick = () => {
		fileInput.current.click();
	};
	
	const closeModal = () => {
		setSlideName('');
		setUploadedImage({
			file: null,
			name: null,
			type: null,
			url: null
		});
		setShowModal(false);	
	}

	const saveSlide = async () => {
		if (!slideName || !uploadedImage.type) {
			return;
		}

		// Show loading screen while processing
		setLoading(true);

		var s3Url = '';

		// Upload to AWS if a new image was uploaded, regardless
		// of whether we're creating or editing a slide.
		if (uploadedImage.url !== slide?.url) {
			const slideType = uploadedImage.file.type.split('/')[0];
			const fileType = uploadedImage.file.type.split('/')[1];

			const slideKey = uuidv4();
			const awsUrl = await getSlideUploadUrl(customerId, slideKey, slideType, fileType)
			.then(async resp => {
				await uploadSlideToAws(resp, uploadedImage.file, uploadedImage.file.type)
				.then(async response => {
					//Successful upload to s3
					if (response.status === 200) {
						s3Url = `${process.env.REACT_APP_AWS_URL}/${customerId}/${slideKey}.${fileType}`;
					} else {
						setErrorMessage('There were issues uploading to S3. Status code: ' + response.status);
						setLoading(false);
						return;
					}
				})
			});
		}

		if (!slide && s3Url !== '') {
			await createSlide({
				customerId,
				label: slideName,
				url: s3Url,
				type: 'image',
			});
		} else if (slide) {
			var doUpdate = false;
			if (s3Url !== '') {
				slide.urlToDelete = slide.url;
				slide.url = s3Url;
				doUpdate = true;
			}
			if (slideName !== slide.label) {
				slide.label = slideName;
				doUpdate = true;
			}
			if (doUpdate) {
				await updateSlide(slide.id, slide)
				.then(async response => {
					if (response.status === 200) {
						console.log('Successfully updated slide');
					} else {
						setErrorMessage('There were issues updating the slide. Status code: ' + response.status);
						setLoading(false);
						return;
					}
				})
			}
		}

		// Show content again
		setLoading(false);
		setShowModal(false);
		setScrollRight(true);
	}

	const updateSlideName = (e) => {
		setSlideName(e.target.value);
		setEnabledButtons(true);
	}

	const getUploadTemplate = () => {
		return <>
			<img className="icon" src={addImage} />
			<div className="add-slide-instructions">
				Upload JPG or GIF image files. Recommended image dimensions are:
				<br/><br/>
				<strong>Standard Display</strong> : 1920px x 1080px  (16:9 ratio)<br/>
				<strong>4k Display</strong> : 3840px x 2160px  (16:9 ratio)
			</div>
		</>
	}

	const confirmDeleteSlide = async () => {
		var message = '';
		if (slidePlaylists.length > 0) {
			message = 'This content is used by one or more playlists. If you proceed, this content will be removed from all affected playlists. This action cannot be undone.';
		} else {
			message = 'If you proceed, this content will be removed. This action cannot be undone.';
		}
		slidePlaylists.forEach((sPlaylist) => {
			console.log(sPlaylist.playlist.label);
		});
		setConfirmDeleteMessage(message);
		setShowConfirmDeleteModal(true);
	}

	const deleteSlide = async () => {
		setUploadedImage({
			file: null,
			name: null,
			type: null,
			url: null
		});
		// Delete slide from Slides and PlaylistsSlides tables
		console.log('remove slide', slide);
		await removeSlide(slide.id);
		setSlideName('');
		setShowModal(false);
		window.location.reload(true);
	}

	return <div className="Modal">
		{
			showConfirmDeleteModal ?
			<ConfirmModal message={confirmDeleteMessage} setShowConfirmDeleteModal={setShowConfirmDeleteModal} deleteSlide={deleteSlide} /> :
			<></>
		}
		<div className="ModalWindow">
			{
				loading
				? <div className="Loading">
					<Circles
						height="80"
						width="80"
						color="#2386EE"
						ariaLabel="circles-loading"
						visible={true}
					/>
				</div>
				: <>
					<div className="slide-container">
						<div className="add-slide-container">
							<div className={`add-slide ${uploadedImage?.url ? 'no-border' : ''}`} ref={dropFile}>
							{
								uploadedImage?.url
								? <div className="uploaded-image">
									<img src={uploadedImage.url}></img>
								</div>
								: getUploadTemplate()
							}
							</div>
						</div>
						<div className="icon-column">
							<div className="icon-box" title="Upload image" style={{backgroundColor: 'var(--Blueberry, #2386EE)'}} onClick={handleClick}>
								<img className="icon upload-icon" src={uploadIcon} />
								<input
									type="file"
									onChange={e => onUpload(e.target.files)}
									ref={fileInput}
									style={{display: 'none'}} // Make the file input element invisible
								/>
							</div>
							<div className="icon-box" title="Use a template">
								<img className="icon" src={templateIcon} />
							</div>
							<div className="icon-box" title="Request design services - coming soon">
								<img className="icon" src={brushIcon} />
							</div>
							{ /* Allow users to hover over this garbage bin so that they know what it does.*/ }
							<div className="icon-box" title="Delete slide" onClick={() => (slide ?  confirmDeleteSlide() : void(0))}>
								<img className="icon" src={slide ? deleteIcon : deleteDisabledIcon } />
							</div>
						</div>
					</div>
					{
						typeError
						? <p className="error-text">Incorrect input type</p>
						: <p className="error-text" style={{visibility: 'hidden'}}>Hidden</p>
					}
					<div className="input-container">
						<label>Label:</label>
						<input value={slideName} onChange={e => updateSlideName(e)} placeholder="Give this image a label to make it easier to find in your content library."></input>
					</div>
					<div className="input-guideline">
						Maximum 120 characters
					</div>			
					<div className="error-message">{errorMessage}</div>
					<div className="buttons-container">
						<button className="secondary-button" onClick={closeModal}>Cancel</button>
						<button disabled={!enabledButtons} className={`${enabledButtons ? 'primary-button' : 'disabled-button'}`} onClick={saveSlide}>Save</button>
					</div>
				</>
			}

		</div>
	</div>
};

const ConfirmModal = ({message, setShowConfirmDeleteModal, deleteSlide}) => {
	return <div className="delete-slide-modal ModalWindow">
			<div>
				{message}
			</div>
			<div className="buttons-container">
				<button className="secondary-button" onClick={() => setShowConfirmDeleteModal(false)}>Cancel</button>
				<button className="primary-button" onClick={() => deleteSlide()}>Delete</button>
			</div>
	</div>
}
