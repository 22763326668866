import React, {
    useState,
    useEffect
} from 'react';
import Select from 'react-select';
import AddressSearch from './AddressSearch';
import { getTimezone, getContentPlaylists } from './utils';
import pauseIcon from '../../images/icons/screens/pause.svg';
import transitIcon from '../../images/icons/screens/transit.svg';
import playlistIcon from '../../images/icons/screens/playlist.svg';
import globeIcon from '../../images/icons/screens/globe.svg';
import MapWrapper from './MapWrapper';

const ScreenConfiguration = ({
    state,
    setState,
    handleInputChange,
    handleMultiInputChanges,
    customerId,
    selectedProduct
}) => {

    const LANGUAGE_OPTIONS = [
        {
            value: 'en',
            label: 'English'
        },        
        {
            value: 'fr',
            label: 'French'
        },
        {
            value: 'fr_ca',
            label: 'French Canada'
        },
        {
            value: 'ge',
            label: 'German'
        },
        {
            value: 'pt',
            label: 'Portuguese'
        },
    ]

    const playlist = {...state?.playlist};

    const [contentPlaylists, setContentPlaylists] = useState([]);

    useEffect(() => {
        getContentPlaylists(customerId)
        .then(data => {
            setContentPlaylists(data)
        })
    }, []);

    const handleAddressSelect = async (address) => {
        await getTimezone( address.latitude, address.longitude)
        .then(timezone => {
            playlist.latitude = address.latitude;
            playlist.longitude = address.longitude;
            playlist.address = address.address;
            playlist.timezone = timezone;
            setState({
                ...state,
                playlist: playlist
            });
        });
    }

    const handleRadioSelect = (radioValue) => {
        //Transportation radio button clicked
        if (radioValue === 'transportation' || radioValue === 'standby') {
            handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], [radioValue, null, null]);
        }
        else if (radioValue === 'contentShortCode') {
            handleMultiInputChanges(['screenType', 'otherSlideUrl'], [radioValue, null]);
        }
        else if (radioValue === 'other') {
            handleMultiInputChanges(['screenType', 'contentShortCode'], [radioValue, null]);
        }
    }

    return (
        <div className="ScreenConfigurationPage">
            <div className="ScreenConfiguration">
                <div className="left-col pure-u-1-2">
                    <h2>Screen configuration</h2>
                    <div className="input-container">
                        <p className="bold pure-u-1-5">Activation code:</p>
                        <p className="bold text">{playlist.shortCode}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Screen name:</p>
                        <input type="text" className="input-field" value={playlist.label || ''} onChange={(e) => handleInputChange('label', e.target.value)} />
                    </div>
                    <h3>Location</h3>
                    {
                        selectedProduct === 'screen_pro'
                        ? <p>This is the installation or assigned location for your Screen Pro. Your Screen Pro will use this, coupled with your other curated content, to determine what to show for transportation, weather, nearby amenities, and more.</p>
                        : <p>Search by address or click and drag the map pin to an exact location. Your Screen will use this to automatically determine what to show for transportation, weather, nearby amenities, and more.</p>
                    }
                    <div className="input-container">
                        <p className="pure-u-1-5">Address:</p>
                        <AddressSearch 
                            handleAddressChanges={handleInputChange} 
                            handleResultSelect={handleAddressSelect}
                            value={ playlist.address || '' }
                            propName='address'
                            disabled={selectedProduct === 'screen_pro'}
                        />
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Coordinates:</p>
                        <p className="text">{playlist.latitude ? `${(playlist.latitude * 1).toFixed(6)}, ${(playlist.longitude * 1).toFixed(6)}` : ''}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Time zone:</p>
                        <p className="text">{ playlist.timezone ?? ''}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Language:</p>
                        <Select  
                            onChange={(option) => handleInputChange('language', option)}
                            options={LANGUAGE_OPTIONS}
                            value={ LANGUAGE_OPTIONS.find(lang => lang.value === playlist?.language) }
                            className={"input-field"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isDisabled={selectedProduct === 'screen_pro'}
                        />
                    </div>
                    <h3 style={{visibility: "hidden"}}>Display</h3>
                    <p style={{visibility: "hidden"}}>Adjust these settings to ensure your Screen looks its best on nearly any display. Be sure to save your changes and then check your Screen in person to ensure things look right!</p>
                    {/* <div className="input-container">
                        <p className="pure-u-1-5">Resolution:</p>
                        <input type="range" className="input-field" min="0" max="4" step="1" />
                    </div> */}
                </div>
                <div className="pure-u-1-2" id="map-container">
                    <MapWrapper state={state} setState={setState} />

                </div>
            </div>
            <div className="ScreenTypeConfiguration">
                <div className="left-col pure-u-1-2">
                    <h2>What should be playling on this Screen?</h2>
                    <p>Do you want to show Transportation information or perhaps a Playlist with some messages for your office or residence? Simply select from the options below and press the save icon in the top right corner when ready. After saving, you can also show what’s playing on your screen by clicking the “eye” in the top right corner.</p>
                    <div className="screen-type-container">
                        <input type="radio" checked={playlist.screenType === 'standby'} onChange={() => handleRadioSelect('standby')} />
                        <img className="type-icon pure-u-1-10" src={pauseIcon} />
                        <p className="text pure-u-1-3">Standby</p>
                    </div>
                    <div className="screen-type-container">
                        <input type="radio" checked={playlist.screenType === 'transportation'} onChange={() => handleRadioSelect('transportation')} />
                        <img className="type-icon pure-u-1-10" src={transitIcon} />
                        <p className="text pure-u-1-3">Only Transportation</p>
                    </div>
                    <div className="screen-type-container">
                        <input type="radio" checked={playlist.screenType === 'contentPlaylist'} onChange={() => handleRadioSelect('contentPlaylist')} />
                        <img className="type-icon pure-u-1-10" src={playlistIcon} />
                        <p className="text pure-u-1-3">One of my Playlists:</p>
                        <Select                          
                            onChange={(option) => handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], ['contentPlaylist', option.value, null])}
                            options={contentPlaylists.map(pl => {
                                return {
                                    label: pl.label,
                                    value: pl.shortCode
                                }
                            })}
                            value={contentPlaylists.find(pl => pl.shortCode === playlist.contentShortCode)}
                            className={"dropdown-field"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuShouldScrollIntoView={false}
                        />
                    </div>
                    <div className="screen-type-container">
                        <input type="radio" checked={playlist.screenType === 'other'} onChange={() => handleRadioSelect('other')} />
                        <img className="type-icon pure-u-1-10" src={globeIcon} />
                        <p className="text pure-u-1-3">Other website:</p>
                        <input type="text" style={{background: '#fff'}} value={playlist?.otherSlideUrl || ''} onChange={(e) => handleMultiInputChanges(['screenType', 'otherSlideUrl', 'contentShortCode'], ['other', e.target.value, null])} className="input-field" placeholder='https://...' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScreenConfiguration;
