const ReportInput = ({
    title,
    placeholder,
    value,
    changeState,
    propName,
    showError
}) => {

    return (
        <div className="ReportInput">
            <p className="title">{title}</p>
            <input
                placeholder={placeholder}
                className={`report-input ${showError ? 'error ' : null}`} 
                value={value} 
                onChange={(e) => changeState([propName], [e.target.value])}
            />
        </div>
    );
}

export default ReportInput;
