import { useState, useEffect, useRef } from 'react';
import { Circles } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import {getSlideUploadUrl, uploadSlideToAws, createSlide } from './controller';
import addImage from '../../images/icons/add-image.svg';
import brushIcon from '../../images/icons/brush.svg';
import deleteIcon from '../../images/icons/analytics/trash.svg';
import uploadIcon from '../../images/icons/upload.svg';
import templateIcon from '../../images/icons/template.svg';

export default function SlideModal({ setShowModal, customerId, slide, setScrollRight }) {
	const [uploadedImage, setUploadedImage] = useState({
		file: null,
		name: null,
		type: null,
		url: null
	});
	const [slideName, setSlideName] = useState('');
	const [typeError, setTypeError] = useState(false);
	const [loading, setLoading] = useState(false);
	const dropFile = useRef(null);
	const fileInput = useRef(null);

	useEffect(() => {
		if (slide) {
			setSlideName(slide.label);
		}
	}, [slide]);

	useEffect(() => {
		//Add event lister for file upload
		dropFile.current.addEventListener('dragover', handleDragOver);
		dropFile.current.addEventListener('drop', handleDrop);
	  
		return () => {
			dropFile.current.removeEventListener('dragover', handleDragOver);
			dropFile.current.removeEventListener('drop', handleDrop);
		};
	}, []);

	const onUpload = async (upload) => {
		const file = upload[0];
		//Only accept image types jpeg/jpg and gif
		if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/gif') {
			setTypeError(true);
			return;
		}
		const objectUrl = URL.createObjectURL(file);
    	setUploadedImage({
			file: file,
			name: file.name,
			type: file.type.split('/')[1],
			url: objectUrl
		});
		setTypeError(false);
	};
	  
	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	  
		const {files} = e.dataTransfer;
	  
		if (files && files.length) {
		  onUpload(files);
		}
	};

	const handleClick = () => {
        fileInput.current.click();
    };
	
	const closeModal = () => {
		setUploadedImage({
			file: null,
			name: null,
			type: null,
			url: null
		});
		setShowModal(false);	
	}

	const uploadSlide = async () => {
		if (!slideName || !uploadedImage.type) {
			return;
		}
		//Show loading screen while processing
		setLoading(true);
		const slideType = uploadedImage.file.type.split('/')[0];
		const fileType = uploadedImage.file.type.split('/')[1];

		const slideKey = uuidv4();

		const awsUrl = await getSlideUploadUrl(customerId, slideKey, slideType, fileType)
		.then(async resp => {
			await uploadSlideToAws(resp, uploadedImage.file, uploadedImage.file.type)
			.then(async response => {
				//Successful upload to s3
				if (response.status === 200) {
					//Upload slide to BIZ here
					await createSlide({
						customerId,
						label: slideName,
						url: `${process.env.REACT_APP_AWS_URL}/${customerId}/${slideKey}.${fileType}`,
						type: 'image',
					})
				}
				//Show content again
				setLoading(false);
				setShowModal(false);
				setScrollRight(true);
			})
		});
	}

	const updateSlideName = (e) => {
		if (slide) {
			slide.label = e.target.value;
		}
		setSlideName(e.target.value);
	}

	return <div className="Modal">
		<div className="ModalWindow">
			{
				loading
				? <div className="Loading">
					<Circles
						height="80"
						width="80"
						color="#2386EE"
						ariaLabel="circles-loading"
						visible={true}
					/>
				</div>
				: <>
					<div className="slide-container">
						<div className="add-slide-container pure-u-1-2">
							<div className="add-slide" ref={dropFile}>
								{
									uploadedImage?.url
									? <img className="uploaded-image" src={uploadedImage.url}></img>
									: slide
										? <img className="uploaded-image" src={slide.url}></img>
										: <>
											<img className="icon" src={addImage} />
											<p>Upload JPG or GIF image files. Recommended image dimensions are: </p>
											<br />
											<p>Standard Display :1920px x 1080px  (16:9 ratio)</p>
											<p>4k Display : 3840px x 2160px  (16:9 ratio)</p>
										</>
								}
							</div>
						</div>
						<div className="icon-column pure-u-1-8">
							<div className="icon-box" style={{backgroundColor: 'var(--Blueberry, #2386EE)'}} onClick={handleClick}>
								<img className="icon" src={uploadIcon} />
								<input
									type="file"
									onChange={e => onUpload(e.target.files)}
									ref={fileInput}
									style={{display: 'none'}} // Make the file input element invisible
								/>
							</div>
							<div className="icon-box">
								<img className="icon" src={templateIcon} />
							</div>
							<div className="icon-box">
								<img className="icon" src={brushIcon} />
							</div>
							<div className="icon-box" onClick={() => setUploadedImage({
								file: null,
								name: null,
								type: null,
								url: null
							})}>
								<img className="icon" src={deleteIcon} />
							</div>
						</div>
					</div>
					{
						typeError
						? <p className="error-text">Incorrect input type</p>
						: <p className="error-text" style={{visibility: 'hidden'}}>Hidden</p>
					}
					<div className="input-container">
						<label>Label</label>
						<input value={slideName} onChange={e => updateSlideName(e)}></input>
					</div>			
					<div className="buttons-container" style={{marginRight: '3em'}}>
						<button className="secondary-button" onClick={closeModal} style={{width: '10vw'}}>Cancel</button>
						<button className="primary-button" onClick={uploadSlide} style={{width: '10vw'}}>Save</button>
					</div>
				</>
			}

		</div>
	</div>
};
