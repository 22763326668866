import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

export default function Draggable(props) {
	const {attributes, listeners, setNodeRef, transform} = useDraggable({
	  id: props.id,
	  type: props.type,
	  slide: props.slide
	});
	const style = {
	  transform: props.allowDrag ? CSS.Translate.toString(transform) : null,
	  cursor: 'pointer',
	  position: 'relative',
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	  justifyContent: 'center',
	  borderRadius: '5px',
	  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	  background: '#fff',
	  overflow: 'hidden',
	  zIndex: 10
	};
  
	return (
	  <div ref={setNodeRef} style={style} {...listeners} {...attributes} onClick={props.onClick} className={props.className} >
		{props.children}
	  </div>
	);
}