import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv/config'
import App from './App';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';

// Force SSL
if (window.location.href.indexOf('localhost') === -1 && window.location.protocol !== 'https:') {
	window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

if (!window.google) {
	const script = document.createElement('script');
	script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.REACT_APP_GMAPS_KEY}&callback=initMap`;
	script.async = true;
	window.initMap = function () { };
	document.head.appendChild(script);
}

ReactDOM.render(
	<React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();