import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWithRouterAccess from './AppWithRouterAccess';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { Provider } from './components/Context';

import './styles/global.scss';

const token = '%iw6%oDZx2kAd!9F';
const cache = new InMemoryCache();
const link = new HttpLink({
    uri: process.env.HASURA_GRAPHQL_ENDPOINT || 'http://localhost:8080/v1/graphql',
    headers: {
        'content-type': 'application/json',
        'x-hasura-admin-secret': token,
    },
});

const client = new ApolloClient({
    cache,
    link,
});

const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <Provider>
        <AppWithRouterAccess/>
      </Provider>
    </ApolloProvider>
  </Router>
);
export default App;