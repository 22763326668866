const getPlaylistData = async (customerId) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/getPlaylistsForContentManagement/${customerId}`;
    return await fetch(url)
    .then(response => response.json())
    .then(data => {
        //Filter by base playlists
        var screenContent = data.filter(pl => {
            return pl?.playlist?.type === 'content' && pl?.playlist?.product === 'screen'
        });
        // JS sort sorts the array in place.
        screenContent.sort((a, b) => a.playlist.label.localeCompare(b.playlist.label));

        return screenContent;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const createOrUpdatePlaylistSlide = async (playlistSlides) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlistsSlides/createOrUpdate`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(playlistSlides)
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });    
}

const removePlaylist = async (playlistId) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/remove/${playlistId}`

    return await fetch(url)
    .then(response => {
        return response
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
        return error
    });
}

const getSlideUploadUrl = async (customerId, slideKey, slideType, fileType) => {
    return await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/slides/getSlideUploadUrl/${customerId}/${slideKey}/${slideType}/${fileType}`)
    .then(response => response.json())
}

const uploadSlideToAws = async (url, file, type) => {
    return await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': type
        }
    })
    .then(response => {
        return response
    })
    .catch(error => {
        console.error(error);
    });
}

const createSlide = async (body) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/slides/createSlide`;

    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
    
    return;
}

const createPlaylist = async (body) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/createPlaylist`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    .then((response) => response.json())
    .then(data => data)
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const setLoopDuration = (playlistSlides, slideCount) => {
    let lastPs = {};
    let lastIndex;
    //Find the last playlist slide with content
    for (let i = slideCount - 1; i >= 0; i--) {
        if (playlistSlides[i]?.url && !lastPs?.url) {
            lastPs = playlistSlides[i]
            lastIndex = i;
        }
        //If this playlist slide has duration set to permanent, set it back to default 10sec
        else if (playlistSlides[i]?.durationMs === null) {
            playlistSlides[i].durationMs = 10000
        }
    }
    if (lastIndex) {
        lastPs.durationMs = null;
        playlistSlides[lastIndex] = lastPs;
    }    
    return playlistSlides;
}

export {
    getPlaylistData,
    createOrUpdatePlaylistSlide,
    removePlaylist,
    getSlideUploadUrl,
    uploadSlideToAws,
    createSlide,
    createPlaylist,
    setLoopDuration
}
